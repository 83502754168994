body {
  background-color: #E1E1B7;
}

.App {
  /* text-align: center; */
  background-color: #E1E1B7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.author {
  margin-left: 10px;
  font-style: italic;
}

.rightPad {
  padding-right: 10px;
}

.noBullets {
  list-style-type: none; /* Remove bullets */
}

.header2 {
  font-weight: bold;
  font-size: 1.2em;
}

.menu {
  background: '#CCCC99';
}

/* Track */
body::-webkit-scrollbar-track {
  background: #cccc99 !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #7b3b2e;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content {
  min-height: calc(85vh - 10px);
}
.footer {
  height: 10px;
}

a {
      color: #2C5682;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 800;
      text-decoration: none;
      font-size: 1rem;
}

li {
  text-align: left;
  font-size: 1rem;
  color: #493118;
}
