.Media {
  position: relative;
  background-color: white;
  height: 500px;
  min-height: 500px;
  /* overflow: hidden; */
  position: relative;
  transition: 300ms;
  cursor: pointer;
}

.MediaCaption {
  text-overflow: ellipsis;

  position: absolute;
  bottom: 0;

  padding: 15px;

  background-color: black;
  color: white !important;
  opacity: 0.6;

  width: 100%;
  height: 10%;

  font: 1.5em;
  font-weight: bold;

  transition: 300ms;
  cursor: pointer;
}

.MediaCaption:hover {
  opacity: 0.8;
}

.Project {
  position: relative;
  height: 500px;
  min-height: 500px;
  /* overflow: hidden; */
  padding: 20px;
  color: white;
}
